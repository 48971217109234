import React, { Fragment, useEffect, useState, useMemo } from "react";

import { Row, Col, Card, Table } from "react-bootstrap";
import { Approve_Withdraw, url } from "../../../services/api_function";
import { DownloadExcel } from "react-excel-export";

export const ApproveWithdraw = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [search, setSearch] = useState("");
  const [filteredData, setFilteredData] = useState([]);
  const pageSize = 40;
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;
        const response = await Approve_Withdraw(
          currentPage,
          pageSize,
          { searchQuery: search },
          token
        );
        const { data, totalUser } = response;
        setFilteredData(data);

        const pages = Math.ceil(totalUser / pageSize);
        setTotalPages(pages > 0 ? pages : 1);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, [currentPage, search]);

  const handleNextPage = () => {
    setCurrentPage((prevPage) =>
      prevPage < totalPages ? prevPage + 1 : prevPage
    );
  };

  const handlePreviousPage = () => {
    setCurrentPage((prevPage) => (prevPage > 1 ? prevPage - 1 : prevPage));
  };

  const formatTimestamp = (timestamp) => {
    const date = new Date(timestamp);
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    return `${day}-${month}-${year} ${hours}:${minutes}`;
  };

  const handleSearch = async (e) => {
    const query = e.target.value.trim().toLowerCase();
    const sanitizedQuery = query.replace(/[\\|^$*+?.(){}[\]]/g, "");
    setSearch(sanitizedQuery);
    if (currentPage !== 1) {
      setCurrentPage(1);
    }
  };

  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <div className="input-group" style={{ maxWidth: "300px" }}>
            <input
              type="search"
              id="form1"
              className="form-control"
              placeholder="Search here..."
              onChange={handleSearch}
            />

          </div>

        </div>



        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
                Confirm Withdrawal Details
              </Card.Title>
              <button type="button" class="btn btn-success">   <DownloadExcel
                data={filteredData}
                buttonLabel="Export to Excel"
                fileName="Withdrawal_Data"
                // className="btn btn-success"
                // style={{ marginBottom: "20px", background:"#cccc"}}
              /></button>
            </Card.Header>

            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong>No</strong>
                    </th>

                    <th>
                      <strong>User</strong>
                    </th>
                    <th>
                      <strong>Name</strong>
                    </th>
                    <th>
                      <strong>Amount</strong>
                    </th>

                    <th>
                      <strong>Token Amount</strong>
                    </th>
                    <th>
                      <strong>Type</strong>
                    </th>
                    <th>
                      <strong>Payment Status</strong>
                    </th>
                    <th>
                      <strong>Transaction ID</strong>
                    </th>
                    <th>
                      <strong>Approve</strong>
                    </th>
                    <th>
                      <strong>Request</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {filteredData && filteredData?.length > 0 ? (
                    filteredData?.map((Data, index) => (
                      <tr key={index}>
                        <td>{(currentPage - 1) * pageSize + index + 1}</td>

                        <td>{Data?.user}</td>
                        <td>{Data?.Name}</td>
                        <td>{(Data?.withdrawAmount)?.toFixed(2)}</td>
                        <td>{Data?.withdrawToken}</td>

                        <td>{Data?.wallet_type}</td>
                        <td> <span className="badge bg-success"> Success </span></td>
                        <td>
                          <a
                            href={`https://bscscan.com/tx/${Data?.trxnHash}`}
                            className="text-white"
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            {Data?.trxnHash
                              ? `${Data?.trxnHash?.slice(
                                0,
                                7
                              )}...${Data?.trxnHash?.slice(-5)}`
                              : ""}
                          </a>
                        </td>
                        <td>{formatTimestamp(Data?.timestamp ?? Data?.createdAt)}</td>
                        <td>{formatTimestamp(Data?.createdAt ?? Data?.timestamp)}</td>
                      </tr>
                    ))
                  ) : (
                    <tr>
                      <td colSpan="7">No data found</td>
                    </tr>
                  )}
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
              <div
                className="text-center mb-3 col-lg-6"
                style={{ margin: "auto" }}
              >
                <div className="filter-pagination  mt-3 bg-black">
                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {"<<"}
                  </button>

                  <button
                    className="previous-button"
                    onClick={handlePreviousPage}
                    disabled={currentPage === 1}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    Previous
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    Next
                  </button>

                  <button
                    className="next-button"
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                    style={{
                      background:
                        " linear-gradient(90deg, rgb(50 30 249) 15.9%,rgb(123 17 135) 98.32%)",
                      color: "#fff",
                    }}
                  >
                    {">>"}
                  </button>

                  <span className="text-white">
                    Page {currentPage} of {totalPages}
                  </span>
                </div>
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default ApproveWithdraw;
