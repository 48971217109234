import React, { Fragment, useState, useEffect } from "react";
import { Row, Col, Card, Table, Button } from "react-bootstrap";
import { Protocal } from "../../../services/api_function";
import { Link } from "react-router-dom";
const Rank = () => {
  const [api, setApi] = useState([]);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const userDetails = localStorage.getItem("userDetails");
        const parsedDetails = JSON.parse(userDetails);
        const token = parsedDetails.token;
        const result = await Protocal(token);
        setApi(result.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  return (
    <Fragment>
      <Row>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            width: "100%",
            marginBottom: "20px",
          }}
        >
          <label class="form-label" for="form1"></label>
        </div>
        <Col lg={12}>
          <Card>
            <Card.Header
              style={{ background: "black", border: "1px solid white" }}
            >
              <Card.Title style={{ color: "white", margin: "auto" }}>
              Rank Bonus
              </Card.Title>
            </Card.Header>
            <Card.Body
              style={{ background: "black", border: "1px solid white" }}
            >
              <Table
                responsive
                style={{
                  background: "black",
                  color: "white",
                  borderBottom: "1px solid white",
                }}
              >
                <thead>
                  <tr>
                    <th>
                      <strong> Rank</strong>
                    </th>
                    <th>
                      <strong>Direct Team</strong>
                    </th>

                    <th>
                      <strong> Direct Business</strong>
                    </th>
                    <th>
                      <strong> Team Size</strong>
                    </th>
                    <th>
                      <strong>Team business </strong>
                    </th>
                    <th>
                      <strong>Rank bonus</strong>
                    </th>
                    <th>
                      <strong>Action</strong>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td> Bronze</td>
                    <td> 2</td>
                    <td>$500</td>
                    <td>5</td>
                    <td>$1000</td>
                    <td>$20</td>
                    <td>
                      <Link
                        to="/rank-data?rank=Bronze"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Silver</td>
                    <td> 3</td>
                    <td>$1500</td>
                    <td>10</td>
                    <td>$3000</td>
                    <td>$40</td>
                    <td>
                      <Link
                        to="/rank-data?rank=Silver"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Gold</td>
                    <td> 4</td>
                    <td>$2000</td>
                    <td>25</td>
                    <td>$10,000</td>
                    <td>$140</td>
                    <td>
                      <Link
                       to="/rank-data?rank=Gold"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Platinum</td>
                    <td> 5</td>
                    <td>$2500</td>
                    <td>3 Gold Achiever</td>
                    <td>-</td>
                    <td>$400</td>
                    <td>
                      <Link
                         to="/rank-data?rank=Platinum"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Beryl</td>
                    <td> 6</td>
                    <td>$3000</td>
                    <td>3 Platinum Achiever</td>
                  <td>-</td>
                  <td>$1200</td>
                    <td>
                      <Link
                         to="/rank-data?rank=Beryl"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Sapphire</td>
                    <td> 7</td>
                    <td>$3500</td>
                 <td>3 Beryl Achiever</td>
              
                 <td>-</td>
                 <td>$3600</td>
                    <td>
                      <Link
                      to="/rank-data?rank=Sapphire"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Ruby</td>
                    <td> 8</td>
                    <td>$4000</td>
                   <td>3 Sapphire Achiever</td>
                   <td>-</td>
                   <td>$10,800</td>
                    <td>
                      <Link
                       to="/rank-data?rank=Sapphire"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Emerald</td>
                    <td>8</td>
                    <td>$4500</td>
                   <td> 3 Ruby Achiever</td>
                   <td>-</td>
                   <td>$32,400</td>
                    <td>
                      <Link
                       to="/rank-data?rank=Emerald"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Diamond</td>
                    <td>9</td>
                    <td>$5000</td>
                   <td>3 Emerald Achiever</td>
                   <td>-</td>
                   <td>$97,200</td>
                    <td>
                      <Link
                       to="/rank-data?rank=Diamond"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td>Blue Diamond</td>
                    <td>9</td>
                    <td>$6500</td>
                   <td>3 Diamond Achiever</td>
                   <td>-</td>
                   <td>$2,97,600</td>
                    <td>
                      <Link
                        to="/rank-data?rank=Blue Diamond"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Black Diamond</td>
                    <td>10</td>
                    <td>$8000</td>
                   <td>3 Blue Diamond Achiever</td>
                   <td>-</td>
                   <td>$8,74,800</td>
                    <td>
                      <Link
                        to="/rank-data?rank=Black Diamond"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                  <tr>
                    <td> Platinum Diamond</td>
                    <td >10</td>
                    <td>$10,000</td>
                   <td>3 Black Diamond Achiever</td>
                   <td>-</td>
                   <td>$26,24,400</td>
                    <td>
                      <Link
                         to="/rank-data?rank=Platinum Diamond"
                        style={{ textDecoration: "none", color: "inherit" }}
                      >
                        <i
                          className="bi bi-arrow-right-circle"
                          style={{ fontSize: "30px" }}
                        ></i>
                      </Link>
                    </td>
                  </tr>
                </tbody>
              </Table>
              <div className="d-flex justify-content-between">
                <span>
                  {/* Page{" "} */}
                  <strong>{/* {currentPage} of {totalPages} */}</strong>
                </span>
              </div>
            
            </Card.Body>
          </Card>
        </Col>
      </Row>
    </Fragment>
  );
};

export default Rank;
